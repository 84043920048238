import { SidebarItemProps } from '@/app/components/navigation/SidebarItem';
import useTracking from '@/app/hooks/useTracking';
import { openPDF, replaceEnvInUrl } from '@/app/utils/helpers';
import {
  ButtonEventProperties,
  ButtonEvents,
  NavEventProperties,
} from '@/app/utils/trackingEvents';
import { useTranslations } from 'next-intl';
import {
  BsArrowLeftCircle,
  BsBattery,
  BsBriefcase,
  BsBuilding,
  BsDoorClosed,
  BsFileSpreadsheet,
} from 'react-icons/bs';

const enum BJsSidebarLinks {
  // Interiors
  FLOORING = 'https://bjs.myhomeprojectcenter.com/interiors/flooring/',
  COUNTERTOPS = 'https://bjs.myhomeprojectcenter.com/interiors/countertops/',
  BATH = 'https://bjs.myhomeprojectcenter.com/interiors/bath/',
  KITCHEN_REMODEL = 'https://bjs.myhomeprojectcenter.com/interiors/kitchen-remodel/',
  WATER_HEATERS = 'https://bjs.myhomeprojectcenter.com/interiors/water-heaters/',

  // Exteriors
  SIDING = `https://bjs.myhomeprojectcenter.com/exteriors/siding/`,
  GUTTERS = 'https://bjs.myhomeprojectcenter.com/exteriors/gutters/',
  ROOFING = 'https://bjs.myhomeprojectcenter.com/exteriors/roofing/',
  WINDOWS = 'https://bjs.myhomeprojectcenter.com/exteriors/windows/',
  EXTERIOR_DOORS = 'https://bjs.myhomeprojectcenter.com/exteriors/exterior-doors/',
  GARAGE_DOORS = 'https://bjs.myhomeprojectcenter.com/exteriors/garage-doors/',

  // Energy Savers
  SOLAR = `https://bjs.myhomeprojectcenter.com/energy-savers/solar/`,
  HEATING_AIR = 'https://bjs.myhomeprojectcenter.com/energy-savers/heating-air/',
  GENERATORS = 'https://bjs.myhomeprojectcenter.com/energy-savers/generators/',

  // How It Works
  CERTIFIED_CONTRACTORS = `https://bjs.myhomeprojectcenter.com/how-it-works/certified-contractors/`,
  MEMBER_PLEDGE = 'https://bjs.myhomeprojectcenter.com/how-it-works/member-pledge/',
  PREPARING_FOR_YOUR_PROJECT = 'https://bjs.myhomeprojectcenter.com/how-it-works/preparing-for-your-project/',

  // Free Estimate
  FREE_ESTIMATE = 'https://bjs.myhomeprojectcenter.com/lead-form/',

  //Back to BJs.com
  BACK_TO_BJS = 'https://www.bjs.com/',
}

export const BJsSidebarItems = (): SidebarItemProps[] => {
  const t = useTranslations('components.navMenu.sidebar');
  const { events, trackClick } = useTracking();

  return [
    {
      id: '1',
      name: t('buttons.Interiors'),
      isCollapsible: true,
      icon: <BsDoorClosed size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Flooring`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.FLOORING));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.FLOORING),
              category: t(`buttons.Flooring`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Countertops`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.COUNTERTOPS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.COUNTERTOPS),
              category: t(`buttons.Countertops`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Bath`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.BATH));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.BATH),
              category: t(`buttons.Bath`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Kitchen Remodel`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.KITCHEN_REMODEL));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.KITCHEN_REMODEL),
              category: t(`buttons.Kitchen Remodel`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Water Heaters`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.WATER_HEATERS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.WATER_HEATERS),
              category: t(`buttons.Water Heaters`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '2',
      name: t('buttons.Exteriors'),
      isCollapsible: true,
      icon: <BsBuilding size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Siding`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.SIDING));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.SIDING),
              category: t(`buttons.Siding`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Gutters`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.GUTTERS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.GUTTERS),
              category: t(`buttons.Gutters`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Roofing`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.ROOFING));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.ROOFING),
              category: t(`buttons.Roofing`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Windows`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.WINDOWS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.WINDOWS),
              category: t(`buttons.Windows`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Exterior Doors`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.EXTERIOR_DOORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.EXTERIOR_DOORS),
              category: t(`buttons.Exterior Doors`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Garage Doors`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.GARAGE_DOORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.GARAGE_DOORS),
              category: t(`buttons.Garage Doors`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '3',
      name: t('buttons.Energy Savers'),
      isCollapsible: true,
      icon: <BsBattery size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Solar`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.SOLAR));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.SOLAR),
              category: t(`buttons.Solar`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Heating & Air`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.HEATING_AIR));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.HEATING_AIR),
              category: t(`buttons.Heating & Air`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Generators`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.GENERATORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.GENERATORS),
              category: t(`buttons.Generators`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '4',
      name: t('buttons.How It Works'),
      isCollapsible: true,
      icon: <BsBriefcase size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Certified Contractors`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.CERTIFIED_CONTRACTORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.CERTIFIED_CONTRACTORS),
              category: t(`buttons.Certified Contractors`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Member Pledge`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.MEMBER_PLEDGE));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.MEMBER_PLEDGE),
              category: t(`buttons.Member Pledge`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Preparing for Your Project`),
          onClick: () => {
            openPDF(replaceEnvInUrl(BJsSidebarLinks.PREPARING_FOR_YOUR_PROJECT));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(BJsSidebarLinks.PREPARING_FOR_YOUR_PROJECT),
              category: t(`buttons.Preparing for Your Project`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '5',
      name: t('buttons.Free Estimate'),
      isCollapsible: false,
      icon: <BsFileSpreadsheet size={18} style={{ marginLeft: '3px' }} />,
      onClick: () => {
        openPDF(replaceEnvInUrl(BJsSidebarLinks.FREE_ESTIMATE));
        const trackingProps: NavEventProperties = {
          component: 'Sidebar',
          target: replaceEnvInUrl(BJsSidebarLinks.FREE_ESTIMATE),
        };
        trackClick(events.NAV_CLICKED, trackingProps);
      },
    },
    {
      id: '6',
      name: t(`buttons.Back to BJs`),
      isCollapsible: false,
      icon: <BsArrowLeftCircle size={18} style={{ marginLeft: '3px' }} />,
      onClick: () => {
        openPDF(BJsSidebarLinks.BACK_TO_BJS);
        const trackingProps: NavEventProperties = {
          component: 'Sidebar',
          target: BJsSidebarLinks.BACK_TO_BJS,
        };
        trackClick(events.NAV_CLICKED, trackingProps);
      },
    },
  ];
};
