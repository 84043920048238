import { SidebarItemProps } from '@/app/components/navigation/SidebarItem';
import useTracking from '@/app/hooks/useTracking';
import { openPDF, replaceEnvInUrl } from '@/app/utils/helpers';
import {
  ButtonEventProperties,
  ButtonEvents,
  NavEventProperties,
} from '@/app/utils/trackingEvents';
import { useTranslations } from 'next-intl';
import { BsBriefcase, BsCart, BsFileSpreadsheet } from 'react-icons/bs';

const enum FndSidebarLinks {
  // How it works
  EASY_AS_1_2_3 = 'https://pros.myhomeprojectcenter.com/how-it-works/easy-as-1-2-3/',
  WHY_WE_RE_DIFFERENT = 'https://pros.myhomeprojectcenter.com/how-it-works/why-we-re-different/',
  CERTIFIED_CONTRACTORS = 'https://pros.myhomeprojectcenter.com/how-it-works/certified-contractors/',
  CUSTOMER_PLEDGE = 'https://pros.myhomeprojectcenter.com/how-it-works/customer-pledge/',
  PREPARING_FOR_YOUR_PROJECT = 'https://pros.myhomeprojectcenter.com/how-it-works/preparing-for-your-project/',

  // Products
  BATH_REMODEL = `https://pros.myhomeprojectcenter.com/products/bath-remodel/`,
  BATHROOM_VANITIES = 'https://pros.myhomeprojectcenter.com/products/bathroom-vanities/',
  COUNTERTOPS = 'https://pros.myhomeprojectcenter.com/products/countertops/',
  FAUCETS_SHOWER_HEADS = 'https://pros.myhomeprojectcenter.com/products/faucets-shower-heads/',
  FLOORING = 'https://pros.myhomeprojectcenter.com/products/flooring/',
  SHOWER_DOORS = 'https://pros.myhomeprojectcenter.com/products/shower-doors/',
  SINKS = 'https://pros.myhomeprojectcenter.com/products/sinks/',
  XL_SLABS = 'https://pros.myhomeprojectcenter.com/products/xl-slabs/',
  SHOP_FLOOR_DECOR = 'https://www.flooranddecor.com/',

  // Measure & Estimate
  MEASURE_ESTIMATE = 'https://pros.myhomeprojectcenter.com/lead-form/',
}

export const FnDSidebarItems = (): SidebarItemProps[] => {
  const t = useTranslations('components.navMenu.sidebar');
  const { events, trackClick } = useTracking();

  return [
    {
      id: '1',
      name: t('buttons.How It Works'),
      isCollapsible: true,
      icon: <BsBriefcase size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Easy as 1-2-3`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.EASY_AS_1_2_3));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.EASY_AS_1_2_3),
              category: t(`buttons.Easy as 1-2-3`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Why We're Different`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.WHY_WE_RE_DIFFERENT));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.WHY_WE_RE_DIFFERENT),
              category: t(`buttons.Why We're Different`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Certified Contractors`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.CERTIFIED_CONTRACTORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.CERTIFIED_CONTRACTORS),
              category: t(`buttons.Certified Contractors`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Customer Pledge`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.CUSTOMER_PLEDGE));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.CUSTOMER_PLEDGE),
              category: t(`buttons.Customer Pledge`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Preparing for Your Project`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.PREPARING_FOR_YOUR_PROJECT));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.PREPARING_FOR_YOUR_PROJECT),
              category: t(`buttons.Preparing for Your Project`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '2',
      name: t('buttons.Products'),
      isCollapsible: true,
      icon: <BsCart size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Bath Remodel`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.BATH_REMODEL));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.BATH_REMODEL),
              category: t(`buttons.Bath Remodel`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Bathroom Vanities`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.BATHROOM_VANITIES));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.BATHROOM_VANITIES),
              category: t(`buttons.Bathroom Vanities`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Countertops`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.COUNTERTOPS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.COUNTERTOPS),
              category: t(`buttons.Countertops`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Faucets/Shower Heads`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.FAUCETS_SHOWER_HEADS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.FAUCETS_SHOWER_HEADS),
              category: t(`buttons.Faucets/Shower Heads`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Flooring`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.FLOORING));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.FLOORING),
              category: t(`buttons.Flooring`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Shower Doors`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.SHOWER_DOORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.SHOWER_DOORS),
              category: t(`buttons.Shower Doors`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Sinks`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.SINKS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.SINKS),
              category: t(`buttons.Sinks`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.XL Slabs`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FndSidebarLinks.XL_SLABS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FndSidebarLinks.XL_SLABS),
              category: t(`buttons.XL Slabs`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Shop Floor & Decor`),
          onClick: () => {
            openPDF(FndSidebarLinks.SHOP_FLOOR_DECOR);
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: FndSidebarLinks.SHOP_FLOOR_DECOR,
              category: t(`buttons.Shop Floor & Decor`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '3',
      name: t('buttons.Measure & Estimate'),
      isCollapsible: false,
      icon: <BsFileSpreadsheet size={18} style={{ marginLeft: '3px' }} />,
      onClick: () => {
        openPDF(replaceEnvInUrl(FndSidebarLinks.MEASURE_ESTIMATE));
        const trackingProps: NavEventProperties = {
          component: 'Sidebar',
          target: replaceEnvInUrl(FndSidebarLinks.MEASURE_ESTIMATE),
        };
        trackClick(events.NAV_CLICKED, trackingProps);
      },
    },
  ];
};
