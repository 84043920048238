import React from 'react';

export default function ConfidentRemodelsLogo() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="260"
      height="50"
      viewBox="0 0 433.35 148.47"
      xmlSpace="preserve"
    >
      <rect x="0" width="433.35" height="148.47" style={{ fill: '#3A3A39' }} />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M22.15,18.26l5.98,1.61l-5.98,1.61l-1.61,5.98l-1.61-5.98l-5.98-1.61l5.98-1.61l1.61-5.98L22.15,18.26z
        M412.81,12.28l-1.61,5.98l-5.98,1.61l5.98,1.61l1.61,5.98l1.61-5.98l5.98-1.61l-5.98-1.61L412.81,12.28z M412.81,121l-1.61,5.98
       l-5.98,1.61l5.98,1.61l1.61,5.98l1.61-5.98l5.98-1.61l-5.98-1.61L412.81,121z M433.35,0v148.47H0V0H433.35z M30.87,128.6
       c0-5.7-4.62-10.33-10.33-10.33c-5.7,0-10.33,4.62-10.33,10.33c0,5.7,4.62,10.33,10.33,10.33C26.24,138.93,30.87,134.3,30.87,128.6z
        M30.87,19.87c0-5.7-4.62-10.33-10.33-10.33c-5.7,0-10.33,4.62-10.33,10.33c0,5.7,4.62,10.33,10.33,10.33
       C26.24,30.2,30.87,25.58,30.87,19.87z M423.14,128.6c0-5.7-4.62-10.33-10.33-10.33c-5.7,0-10.33,4.62-10.33,10.33
       c0,5.7,4.62,10.33,10.33,10.33C418.52,138.93,423.14,134.3,423.14,128.6z M423.14,19.87c0-5.7-4.62-10.33-10.33-10.33
       c-5.7,0-10.33,4.62-10.33,10.33c0,5.7,4.62,10.33,10.33,10.33C418.52,30.2,423.14,25.58,423.14,19.87z M20.54,121l-1.61,5.98
       l-5.98,1.61l5.98,1.61l1.61,5.98l1.61-5.98l5.98-1.61l-5.98-1.61L20.54,121z M76.88,35.99c-2.19-4.53-6.21-7.78-12.57-7.78
       c-8.9,0-15.47,6.36-15.47,18.26c0,12.01,6.61,18.26,15.42,18.26c5.39,0,9.57-2.39,12.52-7.38l-3.15-2.29
       c-2.44,4.02-5.39,5.75-9.36,5.75c-6.61,0-11.19-4.83-11.19-14.35c0-9.41,4.58-14.3,11.24-14.3c4.48,0,7.38,2.24,9.46,6.16
       L76.88,35.99z M106.24,28.21c-8.75,0-14.96,6.31-14.96,18.32c0,11.96,6.21,18.21,14.96,18.21c8.85,0,15.11-6.26,15.11-18.21
       C121.35,34.52,115.09,28.21,106.24,28.21z M106.24,32.18c6.46,0,10.89,4.88,10.89,14.35c0,9.41-4.43,14.3-10.89,14.3
       c-6.36,0-10.74-4.88-10.74-14.3C95.5,37.06,99.88,32.18,106.24,32.18z M137.43,28.87v35.21h4.22V36.6l18.42,27.47h3.97V28.87h-4.22
       V56.6l-18.16-27.73H137.43z M180.52,28.87v35.21h4.22V48.61h12.52v-3.87h-12.52V32.84h16.53v-3.97H180.52z M215.48,28.87v35.21h4.22
       V28.87H215.48z M236.7,28.87v35.21h10.12c9.92,0,17.86-6.82,17.86-17.71c0-10.84-7.84-17.5-17.86-17.5H236.7z M240.97,32.84h5.85
       c7.58,0,13.58,5.04,13.58,13.58c0,8.6-6.05,13.74-13.58,13.74h-5.85V32.84z M280.76,28.87v35.21h22.13v-3.97h-17.91V48h13.28v-3.87
       h-13.28V32.84h17.55v-3.97H280.76z M318.26,28.87v35.21h4.22V36.6l18.42,27.47h3.97V28.87h-4.22V56.6l-18.16-27.73H318.26z
        M358.56,28.87v3.97h10.89v31.24h4.22V32.84h10.84v-3.97H358.56z M48.42,76.55v44.01h9.29v-16.28h7.19l9.48,16.28h10.49
       l-10.62-17.43c5.22-1.91,8.46-6.11,8.46-12.72c0-9.22-6.36-13.87-15.84-13.87H48.42z M57.64,84.95h9.41c4.01,0,6.81,1.72,6.81,5.66
       c0,4.01-2.8,5.79-6.81,5.79h-9.41V84.95z M91.22,76.55v44.01h29.45v-8.59h-20.16v-9.73h13.99v-8.27h-13.99v-8.84h19.72v-8.59H91.22z
        M128.75,76.55v44.01h9.29V97.54l10.75,23.02h3.94l10.75-22.71v22.71h9.29V76.55h-9.1l-12.66,27.86l-12.66-27.86H128.75z
        M201.06,75.72c-11.7,0-19.97,7.89-19.97,22.9c0,14.95,8.27,22.77,19.97,22.77c11.7,0,20.03-7.82,20.03-22.77
       C221.1,83.61,212.76,75.72,201.06,75.72z M201.06,84.31c6.49,0,10.81,4.83,10.81,14.31s-4.32,14.25-10.81,14.25
       c-6.55,0-10.69-4.77-10.69-14.25S194.51,84.31,201.06,84.31z M230.06,76.55v44.01h13.48c13.23,0,23.79-8.52,23.79-22.13
       c0-13.55-10.43-21.88-23.79-21.88H230.06z M239.48,85.14h4.13c7.95,0,14.37,4.77,14.37,13.42s-6.42,13.48-14.37,13.48h-4.13V85.14z
        M276.3,76.55v44.01h29.45v-8.59h-20.16v-9.73h13.99v-8.27h-13.99v-8.84h19.72v-8.59H276.3z M313.83,76.55v44.01h30.28v-8.59h-20.99
       V76.55H313.83z M347.28,113.12c3.56,4.45,10.05,7.95,19.34,7.95c10.75,0,18.32-4.71,18.32-13.8c0-6.55-3.88-9.73-8.65-11.58
       c-4.45-1.72-9.8-2.29-13.42-3.63c-1.72-0.64-3.56-1.53-3.56-3.69c0-2.61,2.8-4.01,7.12-4.01c4.77,0,8.21,1.72,10.88,4.39l6.49-5.66
       c-2.73-3.37-8.08-7-16.92-7c-10.62,0-16.47,5.22-16.47,12.66c0,5.72,3.5,8.4,8.21,10.3c4.52,1.84,9.54,2.61,13.1,3.75
       c2.35,0.76,4.26,1.72,4.26,4.52c0,3.5-3.05,5.66-8.78,5.66c-5.79,0-9.86-2.23-13.04-5.53L347.28,113.12z"
      />
    </svg>
  );
}
