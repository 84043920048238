import { Category } from '@/app/types/models';

/**
 * Returns single category based on workOrderId
 * @param workOrderId
 * @param categories
 */
export const getCategoryByWorkOrderId = (
  workOrderId: number,
  categories: Category[],
): Category | undefined => {
  // find the category with the matching workOrderId
  return categories.find((category) => category.workOrderIds.includes(workOrderId));
};
