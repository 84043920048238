import config from '@/app/config/config';
import { RetailerConfig } from '@/app/types/models';
import { RetailerEnum } from '../utils/constants';

export const getRetailerConfig = (retailerId: number): RetailerConfig | undefined =>
  config.find((r) => r.retailerId === retailerId);

export const getRetailerPlaceholderImageUrl = (retailerId: number) =>
  `/images/placeholders/${getRetailerConfig(retailerId)?.placeholderImageUrl}`;

type RetailerMappingValue = { retailerId: number; name: string };

type RetailerMapping = {
  [subdomain: string]: RetailerMappingValue;
};

export const getRetailerMappingByHostname = (hostname: string): RetailerMappingValue => {
  const retailerDomain = hostname.substring(0, hostname.indexOf('.'));
  const retailerMappingList: RetailerMapping[] = JSON.parse(
    process.env.NEXT_PUBLIC_RETAILER_DOMAIN_MAPPING,
  );

  const retailer = retailerMappingList.find((rn: RetailerMapping) => {
    const retailerMappingKey = Object.keys(rn)[0];
    if (retailerMappingKey === retailerDomain) return true;
  });

  if (retailer) {
    return retailer[retailerDomain as keyof typeof RetailerEnum];
  }

  return { retailerId: -1, name: '' };
};

export const getHostnameByRetailerId = (retailerId: RetailerEnum): string => {
  const retailerMappingList: RetailerMapping[] = JSON.parse(
    process.env.NEXT_PUBLIC_RETAILER_DOMAIN_MAPPING,
  );

  const retailer = retailerMappingList.find((rn: RetailerMapping) => {
    const retailerMappingKey = Object.keys(rn)[0];
    if (rn[retailerMappingKey].retailerId == retailerId) return true;
  });

  if (retailer) {
    return Object.keys(retailer)[0];
  }

  return '';
};

export const getRetailerFaviconUrl = (retailerId: number) => {
  const retailerConfig = getRetailerConfig(retailerId);
  const faviconUrl = retailerConfig?.faviconUrl ?? 'favicon.ico';
  return `images/favicons/${faviconUrl}`;
};
