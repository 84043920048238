import { RetailerConfig } from '@/app/types/models';
import {
  LEGACY_MY_PROJECTS_URL_ACEHARDWARE,
  LEGACY_MY_PROJECTS_URL_BJS,
  LEGACY_MY_PROJECTS_URL_CARPETONE,
  LEGACY_MY_PROJECTS_URL_FLOORANDDECOR,
  LEGACY_MY_PROJECTS_URL_FLOORINGAMERICA,
  LEGACY_MY_PROJECTS_URL_LOWES,
  LEGACY_MY_PROJECTS_URL_PROSOURCE,
  LEGACY_MY_PROJECTS_URL_SAMS,
} from '@/app/utils/constants';
import { RetailerEnum } from 'app/utils/constants';
import AceLogo from '../components/images/AceLogo';
import BjsLogo from '../components/images/BjsLogo';
import ImeLogo from '../components/images/ImeLogo';
import LowesLogo from '../components/images/LowesLogo';
import SamsLogo from '../components/images/SamsLogo';
import AceTheme from '../components/layouts/themes/AceTheme';
import BjsTheme from '../components/layouts/themes/BjsTheme';
import ImeTheme from '../components/layouts/themes/ImeTheme';
import LowesTheme from '../components/layouts/themes/LowesTheme';
import ConfidentRemodelsTheme from '../components/layouts/themes/ConfidentRemodelsTheme';
import ConfidentRemodelsLogo from '../components/images/ConfidentRemodelsLogo';
import { BJsSidebarItems } from './sidebar/BjsSidebarItems';
import { AceSidebarItems } from './sidebar/AceSidebarItems';
import { FnDSidebarItems } from './sidebar/FnDSidebarItems';

const lowesConfig: RetailerConfig = {
  retailerId: RetailerEnum.Lowes,
  placeholderImageUrl: 'lowes_placeholder.png',
  faviconUrl: 'lowes_favicon.ico',
  name: "Lowe's",
  logo: <LowesLogo />,
  theme: LowesTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_LOWES,
  accessibilityStatement: 'https://lowes.myhomeprojectcenter.com/accessibility-statement/',
  phoneNumber: '8774771115',
};

const samsConfig: RetailerConfig = {
  retailerId: RetailerEnum.Sams,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'sams_favicon.ico',
  name: "Sam's",
  logo: <SamsLogo />,
  theme: ConfidentRemodelsTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_SAMS,
  accessibilityStatement: 'https://confidentremodels.com/samsclub/about/accessibility-statement',
  phoneNumber: '8774771121',
};

const fndConfig: RetailerConfig = {
  retailerId: RetailerEnum.FND,
  placeholderImageUrl: 'ime_placeholder.png',
  faviconUrl: 'ime_favicon.ico',
  name: 'Floor & Decor',
  logo: <ImeLogo />,
  theme: ImeTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_FLOORANDDECOR,
  accessibilityStatement: 'https://pros.myhomeprojectcenter.com/accessibility-statement/',
  phoneNumber: '8774771017',
  sidebarItems: FnDSidebarItems,
};

const bjsConfig: RetailerConfig = {
  retailerId: RetailerEnum.BJs,
  placeholderImageUrl: 'bjs_placeholder.png',
  faviconUrl: 'bjs_favicon.ico',
  name: 'bjs',
  logo: <BjsLogo />,
  theme: BjsTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_BJS,
  phoneNumber: '8772574747',
  sidebarItems: BJsSidebarItems,
};

const aceConfig: RetailerConfig = {
  retailerId: RetailerEnum.AceHardware,
  placeholderImageUrl: 'ace_placeholder.png',
  faviconUrl: 'ace_favicon.ico',
  name: 'ace',
  logo: <AceLogo />,
  theme: AceTheme,
  phoneNumber: '8669104833',
  sidebarItems: AceSidebarItems,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_ACEHARDWARE
};

const proSourceConfig: RetailerConfig = {
  retailerId: RetailerEnum.ProSource,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Confident Remodels',
  logo: <ConfidentRemodelsLogo />,
  theme: ConfidentRemodelsTheme,
  phoneNumber: '8449026988',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_PROSOURCE
};

const flooringAmericaConfig: RetailerConfig = {
  retailerId: RetailerEnum.FlooringAmericaInHome,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Confident Remodels',
  logo: <ConfidentRemodelsLogo />,
  theme: ConfidentRemodelsTheme,
  phoneNumber: '8449026467',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_FLOORINGAMERICA
};

const carpetOneConfig: RetailerConfig = {
  retailerId: RetailerEnum.CarpetOne,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Confident Remodels',
  logo: <ConfidentRemodelsLogo />,
  theme: ConfidentRemodelsTheme,
  phoneNumber: '8449027100',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_CARPETONE
};

export default [
  lowesConfig,
  samsConfig,
  fndConfig,
  aceConfig,
  bjsConfig,
  proSourceConfig,
  flooringAmericaConfig,
  carpetOneConfig,
];
