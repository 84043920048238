import { SidebarItemProps } from '@/app/components/navigation/SidebarItem';
import useTracking from '@/app/hooks/useTracking';
import { openPDF, replaceEnvInUrl } from '@/app/utils/helpers';
import {
  ButtonEventProperties,
  ButtonEvents,
  NavEventProperties,
} from '@/app/utils/trackingEvents';
import { useTranslations } from 'next-intl';
import { BsBriefcase, BsCart, BsFileSpreadsheet } from 'react-icons/bs';

const enum AceSidebarLinks {
  // How it works
  EASY_AS_1_2 = 'https://acehardware.myhomeprojectcenter.com/how-it-works/easy-as-1-2/?returnUrl=%2fwelcome-sheds-wizard%2f',
  AUTHORIZED_PROVIDERS = 'https://acehardware.myhomeprojectcenter.com/how-it-works/authorized-providers/',
  CUSTOMER_PLEDGE = 'https://acehardware.myhomeprojectcenter.com/how-it-works/customer-pledge/',
  PREPARING_FOR_YOUR_PROJECT = 'https://acehardware.myhomeprojectcenter.com/how-it-works/preparing-for-your-project/',

  // Products
  GENERATORS = `https://acehardware.myhomeprojectcenter.com/products/generators/`,
  SHEDS = 'https://acehardware.myhomeprojectcenter.com/welcome-sheds-wizard/',

  // Free estimate
  FREE_ESTIMATE = 'https://acehardware.myhomeprojectcenter.com/lead-form/',
}

export const AceSidebarItems = (): SidebarItemProps[] => {
  const t = useTranslations('components.navMenu.sidebar');
  const { events, trackClick } = useTracking();

  return [
    {
      id: '1',
      name: t('buttons.How It Works'),
      isCollapsible: true,
      icon: <BsBriefcase size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Easy as 1-2`),
          onClick: () => {
            openPDF(replaceEnvInUrl(AceSidebarLinks.EASY_AS_1_2));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(AceSidebarLinks.EASY_AS_1_2),
              category: t(`buttons.Easy as 1-2`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Certified Providers`),
          onClick: () => {
            openPDF(replaceEnvInUrl(AceSidebarLinks.AUTHORIZED_PROVIDERS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(AceSidebarLinks.AUTHORIZED_PROVIDERS),
              category: t(`buttons.Certified Providers`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Customer Pledge`),
          onClick: () => {
            openPDF(replaceEnvInUrl(AceSidebarLinks.CUSTOMER_PLEDGE));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(AceSidebarLinks.CUSTOMER_PLEDGE),
              category: t(`buttons.Customer Pledge`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Preparing for Your Project`),
          onClick: () => {
            openPDF(replaceEnvInUrl(AceSidebarLinks.PREPARING_FOR_YOUR_PROJECT));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(AceSidebarLinks.PREPARING_FOR_YOUR_PROJECT),
              category: t(`buttons.Preparing for Your Project`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '2',
      name: t('buttons.Products'),
      isCollapsible: true,
      icon: <BsCart size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Generators`),
          onClick: () => {
            openPDF(replaceEnvInUrl(AceSidebarLinks.GENERATORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(AceSidebarLinks.GENERATORS),
              category: t(`buttons.Generators`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Sheds`),
          onClick: () => {
            openPDF(replaceEnvInUrl(AceSidebarLinks.SHEDS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(AceSidebarLinks.SHEDS),
              category: t(`buttons.Sheds`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '3',
      name: t('buttons.Free Estimate'),
      isCollapsible: false,
      icon: <BsFileSpreadsheet size={18} style={{ marginLeft: '3px' }} />,
      onClick: () => {
        openPDF(replaceEnvInUrl(AceSidebarLinks.FREE_ESTIMATE));
        const trackingProps: NavEventProperties = {
          component: 'Sidebar',
          target: replaceEnvInUrl(AceSidebarLinks.FREE_ESTIMATE),
        };
        trackClick(events.NAV_CLICKED, trackingProps);
      },
    },
  ];
};
