// Top level event names
enum TrackingEvents {
  /* GENERIC */
  BUTTON_CLICKED = 'Button Clicked',
  NAV_CLICKED = 'Nav Clicked',
  NAV_INITIATED = 'Nav Initiated',
  MODAL_OPENED = 'Modal Opened',
  MODAL_CLOSED = 'Modal Closed',

  /* DOCUMENTS */
  DOCUMENT_OPENED = 'Document Opened',
  DOCUMENT_SIGNED = 'Document Signed',

  /* AUTH */
  USER_LOGIN = 'User Login',
  USER_REGISTERED = 'User Registered',

  /* PROFILE */
  PROFILE_UPDATE_ATTEMPTED = 'Profile Update Attempted',
  PASSWORD_SET = 'Password Set',
  PASSWORD_RESET = 'Password Reset',
  PHONE_CHANGE = 'Phone Change',

  /* FINANCING */
  FINANCING_APP_STARTED = 'Financing App Started',
  FINANCING_OFFER_EXPANDED = 'Financing Offer Expanded',

  /* PAYMENT */
  PAYMENT_STARTED = 'Payment Started',
}

// Give some clarity on button actions that don't navigate to a particular route
enum ButtonEvents {
  Submit = 'Submit',
  SignOut = 'SignOut',
  MyProjectsExpanded = 'MyProjectsExpanded',
  MobileBackButton = 'MobileBackButton',
  LeftNavToggled = 'LeftNavToggled',
}

// Generic event outcomes
enum EventOutcomes {
  Attempt = 'Attempt',
  Success = 'Success',
  Failure = 'Failure',
}

// Types to represent custom properties we'll log with different types of events
type BaseEventProperties = {
  widget?: string;
  component?: string;
  issue?: string;
};

type DocumentEventProperties = { document?: string } & BaseEventProperties;

type UserEventProperties = { outcome: EventOutcomes } & BaseEventProperties;

type ProfileEventProperties = { profileProperty?: string } & BaseEventProperties;

type ButtonEventProperties = { buttonEvent?: ButtonEvents } & BaseEventProperties;

type NavEventProperties = { target?: string; category?: string } & BaseEventProperties;

type ModalEventProperties = { modal?: string } & BaseEventProperties;

type CustomEventProperties =
  | BaseEventProperties
  | DocumentEventProperties
  | ButtonEventProperties
  | ModalEventProperties
  | ProfileEventProperties
  | UserEventProperties;

export { TrackingEvents, ButtonEvents, EventOutcomes };

export type {
  CustomEventProperties,
  BaseEventProperties,
  DocumentEventProperties,
  ButtonEventProperties,
  NavEventProperties,
  ModalEventProperties,
  ProfileEventProperties,
  UserEventProperties,
};
